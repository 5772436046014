var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Framework;
        (function (Framework) {
            var Periodicity;
            (function (Periodicity_1) {
                var IntraDayTiming = Framework.Model.IntraDayTiming;
                var PeriodicityFactory = Framework.Factory.PeriodicityFactory;
                var IntraDayTimingFactory = Framework.Factory.IntraDayTimingFactory;
                var DailyAlternationType = RemeCare.Shared.Contract.Code.DailyAlternationType;
                var EvenOddDays = RemeCare.Shared.Contract.Code.EvenOddDays;
                var PeriodicityEditorController = /** @class */ (function (_super) {
                    __extends(PeriodicityEditorController, _super);
                    function PeriodicityEditorController($scope, $translate, toaster, gridBuilderSvc, modalBuilderFactory, masterdataSvc, daySvc, $locale) {
                        var _this = _super.call(this, $scope, $translate, toaster) || this;
                        _this.$scope = $scope;
                        _this.$translate = $translate;
                        _this.toaster = toaster;
                        _this.gridBuilderSvc = gridBuilderSvc;
                        _this.modalBuilderFactory = modalBuilderFactory;
                        _this.masterdataSvc = masterdataSvc;
                        _this.daySvc = daySvc;
                        _this.$locale = $locale;
                        $scope.newPeriodicity = function (d) { return _this.newPeriodicity(d.Id); };
                        $scope.addIntraDayTiming = function () { return _this.addIntraDayTimingAsync(); };
                        $scope.isFirstInRecurrence = function () { return _this.isFirstInRecurrence(); };
                        $scope.isLastInRecurrence = function () { return _this.isLastInRecurrence(); };
                        $scope.setEvenOddDays = function (t) { return _this.setEvenOddDays(t); };
                        $scope.setRecurrentCycleMembers = function (p) { return _this.setRecurrentCycleMembers(p); };
                        $scope.changeDuration = function () { return _this.changeDuration(); };
                        $scope.clearDuration = function () { return _this.clearDuration(); };
                        $scope.changeRecurrenceCycleView = function (s) { return _this.changeRecurrenceCycleView(s); };
                        $scope.isRoot = function () { return _this.isRoot(); };
                        $scope.changeAlternation = function () { return _this.changeAlternation(); };
                        return _this;
                    }
                    PeriodicityEditorController.prototype.$onInit = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        this.$scope.recurrentCycle = _.sortBy(this.$scope.recurrentCycle, function (x) { return x.sequenceInRecurrenceCycle; });
                                        if (this.$scope.recurrentCycle != null && this.$scope.recurrentCycle.length > 0) {
                                            this.$scope.periodicity = _.find(this.$scope.recurrentCycle, function (p) { return p.sequenceInRecurrenceCycle === 0 || p.sequenceInRecurrenceCycle === 1; });
                                            this.$scope.currentSequence = this.$scope.periodicity.sequenceInRecurrenceCycle;
                                        }
                                        return [4 /*yield*/, this.getDurationsUnitsAsync()];
                                    case 1:
                                        _a.sent();
                                        if (this.$scope.forMedication) {
                                            this.$scope.minDurationQuantity = 1;
                                        }
                                        this.$scope.carePlanStartDateReadable = Shared.DateHelper.dateHumanReadible(this.$scope.carePlanStartDate);
                                        this.$scope.prescriptionStartDateReadable = function () {
                                            return Shared.DateHelper.dateHumanReadible(_this.$scope.prescriptionStartDate);
                                        };
                                        this.$scope.days = this.daySvc.getDays();
                                        this.$scope.weekdayOccurences = this.daySvc.getWeekDayOccurences();
                                        this.$scope.months = _(this.$locale.DATETIME_FORMATS.MONTH).map(function (m, i) {
                                            return {
                                                value: i + 1,
                                                name: m,
                                            };
                                        });
                                        this.buildGrid();
                                        this.ensureActiveTab();
                                        this.$scope.$watch('activeTab', function (newValue, oldValue) { return _this.ensureActiveTab(); });
                                        return [2 /*return*/];
                                }
                            });
                        });
                    };
                    PeriodicityEditorController.prototype.confirm = function () {
                        if (this.$scope.form.$invalid) {
                            this.showValidationErrorMessage();
                            this.$scope.showErrors = true;
                            return;
                        }
                        this.$scope.$close(this.$scope.recurrentCycle);
                    };
                    PeriodicityEditorController.prototype.ensureActiveTab = function () {
                        if (!this.$scope.activeTab) {
                            this.$scope.activeTab = 0;
                        }
                    };
                    PeriodicityEditorController.prototype.changeAlternation = function () {
                        this.setRecurrentCycleMembers(this.$scope.periodicity);
                    };
                    PeriodicityEditorController.prototype.isRoot = function () {
                        return this.$scope.periodicity && !this.$scope.periodicity.parentPeriodicity;
                    };
                    PeriodicityEditorController.prototype.setDurationUnits = function () {
                        var _this = this;
                        if (this.$scope.periodicity != null && this.$scope.periodicity.parentPeriodicity != null) {
                            this.$scope.limitedDurationUnits = _(this.$scope.possibleDurationUnits).filter(function (u) { return u.Id <= _this.$scope.periodicity.parentPeriodicity.recurrence.Unit; });
                            this.$scope.extraLimitedDurationUnits = _(this.$scope.possibleDurationUnits).filter(function (u) { return u.Id < _this.$scope.periodicity.parentPeriodicity.recurrence.Unit; });
                        }
                        else {
                            this.$scope.limitedDurationUnits = this.$scope.possibleDurationUnits;
                            this.$scope.extraLimitedDurationUnits = this.$scope.possibleDurationUnits;
                        }
                    };
                    PeriodicityEditorController.prototype.getDurationsUnitsAsync = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var durationUnits, periodicity, e_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, this.masterdataSvc.getDurationUnitsAsync()];
                                    case 1:
                                        durationUnits = _a.sent();
                                        this.$scope.possibleDurationUnits = _(durationUnits).filter(function (unit) { return unit.Id !== Shared.Contract.Code.DurationUnit.Hours; });
                                        if (this.$scope.periodicity == null) {
                                            periodicity = new PeriodicityFactory().createPeriodicityFromType(Shared.Contract.Code.PeriodicityType.PeriodicityDay);
                                            periodicity.recurrence.Unit = this.$scope.possibleDurationUnits[0].Id;
                                            this.$scope.periodicity = periodicity;
                                            this.$scope.recurrentCycle = [periodicity];
                                        }
                                        this.setDurationUnits();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        e_1 = _a.sent();
                                        this.toaster.error(e_1);
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        });
                    };
                    PeriodicityEditorController.prototype.changeRecurrenceCycleView = function (sequence) {
                        var _this = this;
                        this.$scope.currentSequence = sequence;
                        var target = _.find(this.$scope.recurrentCycle, function (p) { return p.sequenceInRecurrenceCycle === _this.$scope.currentSequence; });
                        if (target) {
                            this.$scope.periodicity = target;
                            this.$scope.intraDayTimingsGrid.setData(this.$scope.periodicity.intraDayTimings);
                        }
                    };
                    PeriodicityEditorController.prototype.isFirstInRecurrence = function () {
                        if (!this.$scope.periodicity) {
                            return true;
                        }
                        return this.$scope.periodicity.sequenceInRecurrenceCycle <= 1;
                    };
                    PeriodicityEditorController.prototype.isLastInRecurrence = function () {
                        var maxSequence = _(this.$scope.recurrentCycle).max(function (p) { return p.sequenceInRecurrenceCycle; })
                            .sequenceInRecurrenceCycle;
                        return this.$scope.periodicity.sequenceInRecurrenceCycle === maxSequence;
                    };
                    PeriodicityEditorController.prototype.buildGrid = function () {
                        var _this = this;
                        var builder = this.gridBuilderSvc
                            .createGridBuilder()
                            // No addDateColumn on purpose, because it sets a fixed width
                            .addColumn('preferredTime', 'Periodicity.PreferredTime', {
                            cellFilter: 'date: "HH:mm"',
                            sort: { direction: 'asc' },
                        });
                        if (!this.$scope.limitedConfig) {
                            builder = builder
                                .addColumn('preferredTimeWindowBefore', 'Periodicity.PreferredTimeWindowBefore', {
                                cellFilter: 'timeSpanDisplayFilter',
                            })
                                .addColumn('preferredTimeWindowAfter', 'Periodicity.PreferredTimeWindowAfter', {
                                cellFilter: 'timeSpanDisplayFilter',
                            })
                                .addColumn('reminderElapseTimeAfterOpeningWindow', 'Periodicity.ReminderElapseTimeAfterOpeningWindow', { cellFilter: 'timeSpanDisplayFilter' });
                        }
                        if (this.$scope.forMedication) {
                            builder = builder.addColumn('targetValues[0].quantity', 'Periodicity.Number');
                        }
                        if (this.$scope.readOnly) {
                            builder.addShowDetailButtonColumn(function (i) { return _this.editIntraDayTimingAsync(i); });
                        }
                        else {
                            builder.addEditButtonWithPromiseFunctionColumn(function (i) { return _this.editIntraDayTimingAsync(i); });
                            builder.addConditionalDeleteButtonColumn(function (p) { return _this.$scope.onlyIntakeMoments; });
                        }
                        builder = builder.setExternalSorting(false);
                        this.$scope.intraDayTimingsGrid = builder.build();
                        if (this.$scope.periodicity != null) {
                            this.$scope.intraDayTimingsGrid.setData(this.$scope.periodicity.intraDayTimings);
                        }
                    };
                    PeriodicityEditorController.prototype.setRecurrentCycleMembers = function (firstInSequence) {
                        firstInSequence.isAlternating = firstInSequence.isAlternating && firstInSequence.recurrence.Quantity > 1;
                        if (!firstInSequence.isAlternating) {
                            firstInSequence.sequenceInRecurrenceCycle = 0;
                            this.$scope.recurrentCycle = [firstInSequence];
                        }
                        else {
                            this.$scope.currentSequence = 1;
                            firstInSequence.sequenceInRecurrenceCycle = 1;
                            this.$scope.recurrentCycle.length = firstInSequence.recurrence.Quantity;
                            this.$scope.recurrentCycle[0] = firstInSequence;
                            for (var i = 1; i < this.$scope.recurrentCycle.length; i++) {
                                var periodicity = new PeriodicityFactory().createPeriodicityFromDurationUnit(firstInSequence.recurrence.Unit);
                                firstInSequence.setCommonParts(periodicity, true);
                                periodicity.sequenceInRecurrenceCycle = i + 1;
                                this.$scope.recurrentCycle[i] = periodicity;
                                var member = this.$scope.recurrentCycle[i];
                                member.recurrence = firstInSequence.recurrence;
                                member.duration = firstInSequence.duration;
                            }
                        }
                    };
                    PeriodicityEditorController.prototype.setEvenOddDays = function (dailyAlternationType) {
                        if (dailyAlternationType === DailyAlternationType.EvenOdd) {
                            var day1 = this.$scope.recurrentCycle[0];
                            day1.evenUnevenDays = EvenOddDays.Even;
                            var day2 = this.$scope.recurrentCycle[1];
                            day2.evenUnevenDays = EvenOddDays.Odd;
                        }
                        else {
                            _(this.$scope.recurrentCycle).forEach(function (p) { return (p.evenUnevenDays = null); });
                        }
                        _(this.$scope.recurrentCycle).forEach(function (p) { return (p.dailyAlternationType = dailyAlternationType); });
                    };
                    PeriodicityEditorController.prototype.addIntraDayTimingAsync = function () {
                        return __awaiter(this, void 0, void 0, function () {
                            var intraDayTiming, i, e_2;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        intraDayTiming = this.$scope.therapyActionPartContextSettings
                                            ? new IntraDayTimingFactory().createWithTherapyActionPartContextSettings(this.$scope.therapyActionPartContextSettings)
                                            : this.$scope.forMedication
                                                ? new IntraDayTimingFactory().createIntraDayTimingWithTargetDoses(1)
                                                : new IntraDayTiming();
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 3, , 4]);
                                        return [4 /*yield*/, this.editIntraDayTimingAsync(intraDayTiming)];
                                    case 2:
                                        i = _a.sent();
                                        this.$scope.intraDayTimingsGrid.addRow(i);
                                        return [3 /*break*/, 4];
                                    case 3:
                                        e_2 = _a.sent();
                                        this.toaster.error(e_2);
                                        return [3 /*break*/, 4];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        });
                    };
                    PeriodicityEditorController.prototype.editIntraDayTimingAsync = function (intraDayTiming) {
                        return __awaiter(this, void 0, void 0, function () {
                            var _this = this;
                            return __generator(this, function (_a) {
                                return [2 /*return*/, new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                                        var i;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, this.showIntraDayTimingModalAsync(intraDayTiming)];
                                                case 1:
                                                    i = _a.sent();
                                                    resolve(i);
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); })];
                            });
                        });
                    };
                    PeriodicityEditorController.prototype.showIntraDayTimingModalAsync = function (intraDayTiming) {
                        var _this = this;
                        return new Promise(function (resolve) {
                            intraDayTiming = intraDayTiming.copy();
                            _this.modalBuilderFactory
                                .createModalBuilder()
                                .setTemplateUrl('views/periodicity/intraDayTiming.html')
                                .setController('intraDayTimingCtrl')
                                .setScope({
                                readOnly: _this.$scope.readOnly,
                                intraDayTiming: intraDayTiming.copy(),
                                unit: _this.$scope.medication ? _this.$scope.medication.UnitName : null,
                                forMedication: _this.$scope.forMedication,
                                limitedConfig: _this.$scope.limitedConfig,
                                onlyIntakeMoments: _this.$scope.onlyIntakeMoments,
                            })
                                .setSize(Framework.Helper.ModalSize.large)
                                .setResultCallBack(function (r) { return resolve(r); })
                                .build();
                        });
                    };
                    PeriodicityEditorController.prototype.newPeriodicity = function (durationUnit) {
                        var oldPeriodicity = this.$scope.periodicity;
                        this.$scope.periodicity = new PeriodicityFactory().createPeriodicityFromDurationUnit(durationUnit);
                        var type = this.$scope.periodicity.type;
                        oldPeriodicity.setCommonParts(this.$scope.periodicity);
                        this.$scope.periodicity.type = type;
                        this.$scope.periodicity.childPeriodicity = null;
                        this.$scope.periodicity.sequenceInRecurrenceCycle = 1;
                        this.setRecurrentCycleMembers(this.$scope.periodicity);
                        this.$scope.intraDayTimingsGrid.setData(this.$scope.periodicity.intraDayTimings);
                    };
                    PeriodicityEditorController.prototype.changeDuration = function () {
                        var duration = this.$scope.recurrentCycle[0].duration;
                        for (var i = 1; i < this.$scope.recurrentCycle.length; i++) {
                            var durationToUpdate = this.$scope.recurrentCycle[i].duration;
                            if (durationToUpdate == null) {
                                this.$scope.recurrentCycle[i].duration = {
                                    Quantity: duration.Quantity,
                                    Unit: duration.Unit,
                                };
                            }
                            else {
                                durationToUpdate.Quantity = duration.Quantity;
                                durationToUpdate.Unit = duration.Unit;
                            }
                        }
                    };
                    PeriodicityEditorController.prototype.clearDuration = function () {
                        this.$scope.recurrentCycle[0].duration = {
                            Quantity: null,
                            Unit: null,
                        };
                        this.changeDuration();
                    };
                    return PeriodicityEditorController;
                }(Framework.ControllerBase));
                Shared.remeCareSharedModule.controller('PeriodicityEditorCtrl', PeriodicityEditorController);
            })(Periodicity = Framework.Periodicity || (Framework.Periodicity = {}));
        })(Framework = Shared.Framework || (Shared.Framework = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
