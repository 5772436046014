namespace RemeCare.Shared.Framework {
    class CheckApplicationProfileController implements ng.IController {
        public applicationProfiles: Contract.IEnumTranslation[];

        constructor(
            private readonly $state: ng.ui.IStateService,
            private readonly $stateParams,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly storageSvc: Shared.Framework.Service.StorageService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly informedConsentSvc: Shared.Framework.Service.InformedConsentService,
            private readonly securityApiSvc: Service.SecurityApiSvc,
            private readonly authservice: AuthService
        ) {}

        public $onInit(): void {
            this.getApplicationProfiles();
        }

        public async getApplicationProfiles(): Promise<void> {
            this.applicationProfiles = await this.securityApiSvc.findMyApplicationProfilesAsync();
            const preferred = this.storageSvc.get<Shared.Contract.Code.ApplicationProfileType>(
                'preferredApplicationProfile',
                false
            );
            this.storageSvc.remove('preferredApplicationProfile', false);

            if (this.applicationProfiles.length > 1) {
                if (_(this.applicationProfiles).any((p) => p.Id === preferred)) {
                    this.setApplicationProfile(preferred);
                    return;
                }

                this.$state.go('applicationProfile', {
                    redirect: this.$stateParams.redirect,
                    params: this.$stateParams.params,
                });
            } else if (this.applicationProfiles.length === 1) {
                this.setApplicationProfile(this.applicationProfiles[0].Id);
            } else {
                this.$state.go('unauthorized');
            }
        }

        private async setApplicationProfile(
            applicationProfile: Shared.Contract.Code.ApplicationProfileType
        ): Promise<void> {
            try {
                const r = await this.securityApiSvc.setApplicationProfile(applicationProfile);
                this.authservice.setRights(r.Rights);
                this.authservice.setClaim(ClaimTypes.applicationProfile, applicationProfile.toString());
                this.authservice.setClaim(ClaimTypes.partyRoleId, r.PartyRoleId as string);
                const newState = this.$stateParams.redirect ? this.$stateParams.redirect : 'home';
                this.$state.go(newState, this.$stateParams.params);
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCareSharedModule.controller('checkApplicationProfileController', CheckApplicationProfileController);
}
